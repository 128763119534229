/*
  REMOVE ARROWS/SPINNERS------------------------------------------------------------------------
*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
  PAGES STRUCTURE (HEADER / CONTENT / FOOTER)--------------------------------------------------- 
*/
#header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
}
#content {
  padding-top: 47px;
}
#footer {
  width: 100%;
  z-index: 1000;
  margin-top: auto;
}

/*
  CSS USED BY MULTIPLE PAGES AND COMPONENTS-------------------------------------------------------
*/

.cesiumContainer {
  max-width: 1200px;
  max-height: 1200px;
  margin: 0;
  padding: 0;
}

* {
  padding: 0;
  margin: 0;
}

.bg-gray {
  background-color: #a0a5af;
}

.bg-gray-2 {
  background-color: #dbe1ec;
}

.bg-light-gray {
  background-color: #f5f5f7;
}
.bg-lightest-gray {
  background-color: #fafafa;
}

.bg-blue {
  background-color: #18294d;
}

.bg-white {
  background-color: white;
}

.bg-title {
  /*background-color: #39393a;*/
  background-color: #18294d;
  color: rgb(252, 252, 252);
  font-weight: 700;
  font-size: 15px;
}

.bg-alert {
  background-color: #fff3cd;
}

.border-gray {
  border-style: solid;
  border-color: #f5f5f7;
  border-width: 2px;
  border-top: #f5f5f7;
}

.border-gray-bottom {
  border-bottom: 2px solid #d2d2d3;
}
.border-white-bottom {
  border-bottom: 1px solid #ffffff;
}

.border-radius {
  border-style: solid;
  border-color: #f5f5f7;
  border-width: 2px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.border-sides {
  border-style: solid;
  border-color: #f5f5f7;
  border-width: 2px;
  border-top: #f5f5f7;
  border-bottom: #f5f5f7;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

div {
  padding: 0px;
  margin: 0px;
}

html,
body {
  height: 100%;
  min-height: 100%;
  height: 100%;
}

.field-size-20px {
  min-width: 20px;
  max-width: 20px;
}

.field-size-40px {
  min-width: 40px;
  max-width: 40px;
}

.field-size-50px {
  min-width: 50px;
  max-width: 50px;
}

.field-size-80px {
  min-width: 80px;
}

.field-size-100px {
  min-width: 100px;
}

.field-size-150px {
  min-width: 150px;
}

.field-size-200px {
  min-width: 200px;
}

.field-size-300px {
  min-width: 300px;
}

.field-size-400px {
  min-width: 400px;
}

.hover:hover {
  background-color: #f5f5f7;
}

.icons-social-media {
  color: aliceblue;
}

.img-pt {
  padding-top: 0px;
}

.img-status-size {
  width: 256px;
  height: 49px;
}

input[type="file"] {
  display: none;
}

.link-decoration-none {
  text-decoration: none;
  cursor: auto;
  color: rgb(58, 58, 58);
}
.link-decoration-none:hover {
  color: rgb(58, 58, 58);
}

.list-style-none {
  list-style-type: none;
}
.min-width-1000px {
  min-width: 1000px;
}

.min-width-130px {
  min-width: 130px;
}

.mt-14px {
  margin-top: 7px;
}

.mt-30px {
  margin-top: 30px;
}

.mt-35px {
  margin-top: 38px;
}

p {
  padding: 0;
  margin: 0;
}
.radius-5px {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.radius-10px {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.radius-bottom {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 20px;
}

.radius-top {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.span-text {
  font-size: 12px;
  font-style: italic;
  color: brown;
}

.text-filter {
  font-size: 12px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.text-green {
  color: #0ed145;
}

.text-label {
  font-size: 12px;
  color: rgb(75, 74, 74);
  font-weight: 500;
}

.text-label-button-open {
  font-size: 12px;
  color: rgb(24, 136, 211);
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.text-comment {
  font-size: 12px;
  color: black;
  font-weight: 400;
}

.text-comment-author {
  font-size: 14px;
  color: rgb(19, 2, 170);
  font-weight: 700;
}

.text-decoration-none {
  text-decoration: none;
}

.text-filter-title {
  font-size: 14px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.text-filter-title:hover {
  color: rgb(24, 136, 211);
}

.text-filter-title-open {
  font-size: 14px;
  color: rgb(24, 136, 211);
  font-weight: 600;
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.text-modals {
  font-size: 16px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.text-pt {
  padding-top: 15px;
  font-size: 14px;
}

.text-12 {
  font-size: 12px;
}

.text-pt-white {
  padding-top: 15px;
  font-size: 14px;
  color: white;
}

.text-pt-black {
  padding-top: 15px;
  font-size: 14px;
  font-weight: 500;
  color: rgb(47, 45, 45);
}

.text-pt-orange {
  padding-top: 15px;
  font-size: 14px;
  color: orange;
}

.text-title {
  font-size: 14px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.text-10 {
  font-size: 10px;
  color: rgb(61, 61, 61);
  font-weight: 600;
}

.text-white {
  color: rgb(247, 247, 247);
}

.text-red {
  color: rgb(212, 22, 22);
}

.text-title:hover {
  color: rgb(50, 87, 206);
}

.textAreaFont {
  font-size: 10px;
}

.colour-light-blue {
  color: rgb(126, 180, 230);
}

.colour-light-blue:hover {
  color: rgb(44, 141, 231);
  cursor: pointer;
}

.colour-light-red {
  color: rgb(211, 128, 128);
}

.colour-light-red:hover {
  color: rgb(216, 40, 40);
  cursor: pointer;
}

.width-80 {
  margin-left: 5px;
  margin-right: 5px;
  min-height: 90vh;
}

.width-100 {
  min-width: 100px;
  max-width: 100px;
}
.width-120 {
  min-width: 120px;
  max-width: 120px;
}
.width-140 {
  min-width: 140px;
  max-width: 140px;
}
.width-160 {
  min-width: 160px;
  max-width: 160px;
}
.width-180 {
  min-width: 180px;
  max-width: 180px;
}
.width-200 {
  min-width: 200px;
  max-width: 200px;
}

.width-max-500 {
  max-width: 500px;
}

.width-min-700 {
  max-width: 700px;
  background-color: #0ed145;
}

.weight-600 {
  font-weight: 600;
}
.weight-700 {
  font-weight: 700;
}
.weight-800 {
  font-weight: 800;
}

.buttonContainer {
  position: absolute;
  z-index: 3;
}

.globe {
  margin-bottom: 22px;
}

.globe:hover {
  color: red;
  margin-bottom: 10%;
  cursor: pointer;
}

/* 
  --------- DROP SHADOW STYLING 
*/

.main-body {
  padding: 15px;
}

.card-user {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #f5f5f7;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

.card-body-user {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

/*  
  CONDITIONAL RENDERING BY PAGE WIDTH
*/

@media (min-width: 1300px) {
  .width-80 {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media (max-width: 1000px) {
  .img-pt {
    padding-top: 8px;
  }

  .text-pt {
    padding-top: 5px;
    font-size: 10px;
  }
  .text-pt-white {
    padding-top: 5px;
    font-size: 10px;
    color: white;
  }
  .text-pt-orange {
    padding-top: 5px;
    font-size: 10px;
    color: orange;
  }
  .img-status-size {
    width: 260px;
    height: 50px;
  }
}

@media (max-width: 600px) {
  #hide-600px {
    display: none;
  }
}
