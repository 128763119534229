
.roboto-thin {
    font-family: "Roboto", serif;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-light {
    font-family: "Roboto", serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-regular {
    font-family: "Roboto", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-medium {
    font-family: "Roboto", serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-bold {
    font-family: "Roboto", serif;
    font-weight: 700;
    font-style: normal; 
  }
  
  .roboto-black {
    font-family: "Roboto", serif;
    font-weight: 900;
    font-style: normal;
  }
  
  .roboto-thin-italic {
    font-family: "Roboto", serif;
    font-weight: 100;
    font-style: italic;
  }
  
  .roboto-light-italic {
    font-family: "Roboto", serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .roboto-regular-italic {
    font-family: "Roboto", serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .roboto-medium-italic {
    font-family: "Roboto", serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .roboto-bold-italic {
    font-family: "Roboto", serif;
    font-weight: 700;
    font-style: italic;
  }
  
  .roboto-black-italic {
    font-family: "Roboto", serif;
    font-weight: 900;
    font-style: italic;
  }
  
  #root {
    width: 100%;
    height: 100%;
  }
  a {
    font-weight: 500;
    color: #646cff;
    text-decoration: inherit;
  }
  a:hover {
    color: #535bf2;
  }
  
  body {
    margin: 0;
    display: flex;
    place-items: center;
    justify-content: space-evenly;
    min-width: 320px;
    min-height: 100vh;
  }
  
  h1 {
    font-size: 3.2em;
    line-height: 1.1;
  }
  
  button {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 0.6em 1.2em;
    font-size: 1em;
    font-weight: 500;
    font-family: inherit;
    background-color: #1a1a1a;
    cursor: pointer;
    transition: border-color 0.25s;
  }
  button:hover {
    border-color: #646cff;
  }
  button:focus,
  button:focus-visible {
    outline: 4px auto -webkit-focus-ring-color;
  }
  